<!--
 * @Description:明细&子集 字段组件
 * @Author: 梁平贤
 * @LastEditors: Please set LastEditors
 * @Date: 2019-07-09 18:19:26
 * @LastEditTime: 2024-02-27 16:49:32
 -->
<template>
  <div class="detail-content"
       :style="expandStyle"
       :class="[{ 'no-border': column.showStyle !== '2' }, { 'cos-closeed': showContent.length === 0 }]">
    <!-- <div class="name" @click="toggleShowContent">
      <span style="vertical-align:middle"> {{ column.name }}</span>
    </div> -->
    <el-collapse v-model="showContent"  ref="coll">
      <el-collapse-item :name="column.uuid" :disabled="column.selectLine!=='0'">
        <template slot="title">
          <div class="title" >
            <div style="display: flex;align-items:center;justify-content:space-between;width:100%">
              <div class="name" style="display: flex;align-items:center">
                  <!-- 占行控件样式 -->
                  <en-icon v-if="column.fieldType=='11'" name="iconmingxi-biaoti" size="14px" style="margin-right:5px;color:#3370FF" ></en-icon>
                  <en-icon v-else-if="column.fieldType=='19'" name="iconshoufuxinxi-biaoti" size="14px" style="margin-right:5px;color:#A871F6" ></en-icon>
                  <en-icon v-else name="iconguanlianfangan" size="14px" style="margin-right:5px;color:#46D48A" ></en-icon>
                  <!-- <span class="iconfont fiexdlie" @click.stop="openConfig" v-if="detailType === 'formatTable'">&#xea22;</span> -->
                  <span style="vertical-align:middle" :class="{ 'title-requred': column.requrein === 0 }">{{ column.name }}</span>
                  <span style="vertical-align:middle" v-if="column.subDetails.length > 0" > ({{column.subDetails.length}})</span>
                  <en-icon v-if="column.selectLine==='0'"
                   name="iconzhankai" color="#A9B5C6" class="drop-down" :style="{transform: showContent.length === 0 ? 'rotate(270deg)':'rotate(0deg)'}" ></en-icon>
              </div>
              <div v-if="column.editable" style="display: flex;align-items:center;">
                <div class="detail-btn" v-for="(btn, index) in btnList" :style="btn.style" :key="index" @click.stop="btn.fn">
                  <en-icon v-if="btn.name !== '新增'" :name="btn.icon" style="margin-top:1px;" size="14" > </en-icon>
                  <en-icon v-if="btn.name === '新增'" :name="btn.icon" style="margin-top:1px;" size="10"> </en-icon>
                  <span style="margin-top:1px;">{{ btn.name }}</span>
                </div>
            </div>
          </div>
          <div class="detail-btn" v-for="(btn, index) in noEditBtnList"  :style="btn.style" :key="index" @click.stop="btn.fn">
              <en-icon  :name="btn.icon" style="margin-top:1px;" size="14"> </en-icon>
              <span style="margin-top:1px;">{{ btn.name }}</span>
            </div>
            <div class="input-with-select" v-if="!column.editable&&showInput">
              <transition name="fade">
                <el-input placeholder="请输入内容"
                        v-model="searchKey"
                        v-if="!column.editable&&showInput"
                        v-click-outside="clcikOutInput"
                        @keyup.enter.native="searchDeatail"
                        @focus.stop="titleFocus"
                        @blur="titleBlur" @click.native.stop  >
                  <el-button slot="append" icon="el-icon-search" @click.stop="searchDeatail"></el-button>

                </el-input>
              </transition>

              <transition name="fade">
                <i class='el-icon-search' style="font-size:14px;color:rgb(144, 147, 153)" v-if="!column.editable&&!showInput" @click.stop="showInput=!showInput"></i>
              </transition>
            </div>

          </div>
          <!-- 添加 -->
          <!-- <div class='header-add' v-if="!isTiled&&column.editable">
            <en-icon name='flow-custom1' size='18' @click.native.stop="addLine"></en-icon>
          </div> -->
        </template>
                <components ref="model"  :is="detailType" :column="column" :businessData="businessData" :checkProp="checkProp"></components>
        <div v-if="isLoadMore"
             v-loading="isLoadMoreDetail"
             @click="loadMoreDatas"
             style="color: #91A1B7;font-size: 12px;width: 100%;text-align: center;height: 30px;line-height: 30px;"
        >
          加载更多（{{ column.loadDatas.length }}）
        </div>
      </el-collapse-item>
    </el-collapse>

    <select-add v-if="column.editable" title="选择添加" :businessData="businessData" ref="selectDialog" @selectAddEntered="selectAddEntered" :column="column"></select-add>

    <import-add v-if="column.editable" title="数据导入" ref="importDialog" :column="column" @importSuccess='importSuccess'></import-add>
    <import-add-by-temp v-if="column.editable" ref="importDialogByTemp" :column="column" @importSuccess='importSuccess'></import-add-by-temp>

    <link-add v-if="column.editable" title="选择数据" :detailType='true' ref="linkDialog" @linkCallback="linkCallback" :column="column" :business-data="businessData"></link-add>
    <import-temp-list-dialog ref="importTempListDialog" @openImportDialog="openImportDialog"></import-temp-list-dialog>
  </div>
</template>

<script>
import { componentMixins } from "en-js";
import { cloneDeep, isObject, isArray } from "lodash";
import Utils from "@/views/businessModel/list/data/utils";
import { detailListService } from "@/api/businessModel";
import API from "@/api/businessModel/list";
import selectAdd from "./addType/selectAdd";
import importTempListDialog from "./importDialog/importTempListDialog";
import importAdd from "./addType/importAdd";
import importAddByTemp from "./addType/importAddByTemp";
import linkAdd from "../components/relevancyAdd";
import MxColumnData from "../MxColumnData.js";
import formatTable from "./displayFormat/table";
import formatCard from "./displayFormat/card";
import { AssignmentOptions, BusinessFieldType } from "../../../data/BusinessCommonHeader";

// relationType 关联数据类型 1-表单 2-基础数据 3-管理对象 4-业务建模
// templateDataId 选择模板id
// foldSet 折叠设置  1 默认折叠 2 默认展开
// selAreaParentField 当selAreaField为子集字段时，当前字段存它的父明细区字段
// showListStyle 显示设置列表样式1:平铺式 2:抽屉式 3：卡片式
// detailImport 是否存在导入按钮
// showModel 2 关联添加 1 选择添加
export default {
  name: "BMDetailComponent",
  mixins: [MxColumnData, componentMixins],
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    },
    expandStyle: {
      type: String,
      default: ""
    },
    // prop校验路径
    checkProp: {
      type: String,
      default: "column"
    }
  },
  components: {
    formatTable,
    formatCard,
    selectAdd,
    importAdd,
    linkAdd,
    importTempListDialog,
    importAddByTemp
  },
  computed: {
    detailType() {
      console.log("---====>>", this.column.sub);

      return this.column.showStyle === "1" && !this.hasSubset ? "formatCard" : "formatTable";
    },
    searchSubField() {
      return this.column.sub.map((item) => item.field);
    },
    hasSubset() {
      return this.column.sub.find((item) => item.fieldType === BusinessFieldType.subSet) !== undefined;
    },
    isTiled() {
      // 卡片式如果有子集  默认设置为列表式
      return this.column.showStyle === "3" || this.column.showStyle === "1";
    },

    isLoadMore() {
      return this.column.loadDatas.length > 0;
    }
  },
  data() {
    return {
      showContent: [],
      btnList: [],
      noEditBtnList: [],
      collClick: "",
      searchKey: "",
      showInput: false,
      hideKeyMap: ["cityId", "districtId", "provinceId"],
      oldSubDetail: null, // 明细数据副本
      addSubDetails: {
        name: "新增",
        style: "color:#FFFFFF;background: #3E90FE;font-size:14px",
        id: "relatedPlanFlag",
        icon: "iconxinzeng1",
        fn: this.addSubDetail
      },
      linkSetting: {
        name: "关联",
        style: "color:#FFFFFF;background-color:#45D489",
        id: "relatedPlanFlag",
        icon: "iconguanliantubiao",
        fn: this.linkAdd
      },
      selectSetting: {
        name: "添加",
        style: "color:#FFFFFF;background: #3E90FE;",
        id: "relatedPlanFlag",
        icon: "xuanzetianjia-xianxing",
        fn: this.selectAdd
      },
      importSetting: {
        name: "导入",
        id: "detailImport",
        style: "color:#26C393;border: 1px solid #FFA32B;color:#FFA32B",
        icon: "daoru-danchuang",
        fn: this.importAdd
      },
      exportSetting: {
        name: "导出",
        style: "color:#26C393;border: 1px solid #FFA32B;color:#FFA32B",
        id: "detailExport",
        icon: "icondaochu",
        fn: this.exportDetailData
      },
      isLoadMoreDetail: false,
      showTip: false,
      checkResult: [],
      baseLineData: null // 新增所用 基础对象数据
    };
  },
  methods: {
    openConfig() {
      console.log(1111);
    },
    async exportDetailData() {
      if (!this.column.subDetails.length) {
        this.$message.warning("当前无数据");
        return;
      }
      const res = await detailListService.mdDataDetailExport({
        id: this.businessData.id,
        templateId: this.column.templateId,
        field: this.column.field,
        version: this.column.version,
        requestType: "stream"
      });
      Utils.exportData(res);
    },
    titleFocus() {
      this.collClick = this.$refs.coll.setActiveNames;
      this.$refs.coll.setActiveNames = () => {};
    },
    titleBlur() {
      this.$refs.coll.setActiveNames = this.collClick;
    },
    addLine() {
      this.$refs.model.addOneLine();
    },
    /**
     * 关联数据回调
     * params {backData: 选中数据}
     */
    linkCallback(type, backData) {
      this.checkResult = backData;
      // this.showTip = true;
      this.changeType(type);
    },
    /**
     * @description:  // 拼接对象属性值
     * @param {hidekeys:不需要拼接的key集合,obj:操作对象}
     * @return {*}
     */
    reduceObjValue(hidekeys, obj) {
      const kList = Object.keys(obj).filter((v) => !hidekeys.includes(v));
      return kList.reduce((n, p) => n + obj[p], "");
    },
    searchDeatail() {
      // this.showContent = true;
      !this.oldSubDetail ? this.oldSubDetail = cloneDeep(this.column.subDetails) : "";// 备份原始数据
      console.log(this.searchKey);
      this.column.subDetails = this.oldSubDetail || this.column.subDetails;// 如果存在原始数据则重新赋值，因为搜索后的数据会被过滤掉一些数据
      this.column.subDetails = this.column.subDetails.filter((item) => item.fields && item.fields.some((fItem) => { // 开始本地搜索
          if (isObject(fItem.columnValue) && fItem.defaultProps) {
            if (isArray(fItem.columnValue)) {
              return item.columnValue && item.columnValue?.some((sItem) => `${sItem[fItem.defaultProps.label]}`.indexOf(this.searchKey) > -1);
            }

            return fItem.showValue && `${fItem.showValue[fItem.defaultProps.label]}`.indexOf(this.searchKey) > -1;
          }
          if (isObject(fItem.columnValue) && !fItem.defaultProps) {
            if (isArray(fItem.columnValue)) {
              return item.columnValue && item.columnValue?.some((sItem) => this.reduceObjValue(this.hideKeyMap, sItem).indexOf(this.searchKey) > -1);
            }
            return this.reduceObjValue(this.hideKeyMap, fItem.columnValue).indexOf(this.searchKey) > -1;
          }

          return fItem.showValue && `${fItem.showValue}`.indexOf(this.searchKey) > -1;
         }));
    },

    // 选择添加 确定回调
    selectAddEntered(backData) {
      this.checkResult = backData;
      const onlyDefaultEmptyLine = this.column.onlyDefaultEmptyLine();// 判断明细区是否只有一行默认的空数据
      this.changeType(!onlyDefaultEmptyLine ? "append" : "cover");
    },

    loadMoreDatas() {
      this.column.loadMoreDetailDatahandle();
    },
    // 导入
    importSuccess(type, data) {
      if (type === 1) { // 依次新增
        this.importType("append", data);
      } else {
        this.importType("cover", data);
      }
    },
    clcikOutInput() {
      !this.searchKey && this.showInput ? this.showInput = false : "";
    },
    changeType(type) {
      const linkedList = [];
      const values = [];

      this.checkResult.forEach((data) => {
        const line = this.column.createOneLineData();
        console.log(line);
        const { lineData, mainData } = this.column.formatMappingRule(data);
        line.refData = {};
        line.refData.id = data.id;
        if (data.detailId) {
          line.refData.detailId = data.detailId;
        }
        if (data.itemDataId) {
          line.refData.itemDataId = data.itemDataId;
        }
        values.push(lineData);
        this.column.setLineValue(line, AssignmentOptions.manual, lineData);
        this.setMainValue(mainData);
        linkedList.push(line);
      });
      if (type === "append") {
        // 追加 backData.length 行数
        this.column.subDetails = this.column.subDetails.concat(linkedList);
      } else {
        // 覆盖当前  多余条删除
        this.column.subDetails = linkedList;
      }
      this.showTip = false;
      console.log(this.column.paramsForSubmit);
    },
    // 填充关联取值的主表字段
    setMainValue(mainData) {
      mainData.forEach((value, column) => {
        column.updateValueWithOptions(value, AssignmentOptions.manual);
      });
    },
    importType(type, list) {
      const linkedList = [];
      list.forEach((data) => {
        const line = this.column.createOneLineData();
        line.refData = {};
        line.refData.id = data.id;
        if (data.detailId) {
          line.refData.detailId = data.detailId;
        }
        if (data.itemDataId) {
          line.refData.itemDataId = data.itemDataId;
        }
        this.column.setLineValue(line, AssignmentOptions.manual, data);
        linkedList.push(line);
      });
      if (type === "append") {
        // 追加 backData.length 行数
        this.column.subDetails = this.column.subDetails.concat(linkedList);
      } else {
        // 覆盖当前  多余条删除
        this.column.subDetails = linkedList;
      }
    },
    openImportDialog(item) {
      console.log(item);
      if (item.type === 0) {
        this.$refs.importDialog.openDialog();
      } else {
        this.$refs.importDialogByTemp.openDialog(item.id);
      }
    },
    // 导入
    async importAdd() {
      const importTemplateList = await API.queryImportTemplate({ templateId: this.column.templateId, area: this.column.field });
      const enableTempList = importTemplateList.filter((item) => item.enableFlag === 0);
      if (!enableTempList.length) {
        this.$message("无可用的导入模板");
        return;
      }
      if (enableTempList.length === 1) {
        enableTempList[0].type === 0 ? this.$refs.importDialog.openDialog() : this.$refs.importDialogByTemp.openDialog(enableTempList[0].id);
      } else {
        this.$refs.importTempListDialog.open(enableTempList, this.column);
      }
    },
    // 关联
    linkAdd() {
      this.$refs.linkDialog.openDialog();
    },
    // 新增
    addSubDetail() {
      console.log("this.column", this.column);
      this.addLine();
    },
    // 选择添加
    selectAdd() {
      !this.column.columnShowContent && (this.column.columnShowContent = this.column.createOneLineData(this.column.showContent));
      // 因数据基础字段缺失 塞入otherTemplate  解决editable 问题
      this.column.columnShowContent?.fields?.forEach((item) => {
        item.otherTemplate = true;
        item.relationTempName = item.relationTempName || this.column.relationTempName;
        item.originalFieldType = item.originalFieldType || item.fieldType;
        item.fieldType === 5 && (item.fieldType = 15); // 把单选改为多选  //fixme  把单选改为多选会存在bug 上一行预存originalFieldType解决bug
      });
      // 选择添加
      this.$refs.selectDialog.openDialog(this.column.columnShowContent);
    },
    // 特殊情况，明细展示
    isCheck() {
      return !this.$route.query.copyId && this.$route.query.isCustom !== "1";
    }
  },
  created() {
    const {
      showModel, detailImport, foldSet, selectLine, relatedPlanFlag, codeCheckIn, editable, allowAddFlag
    } = this.column;
    if ((this.column.showStyle === "3" && allowAddFlag === 0) || (this.column.showStyle === "2" && allowAddFlag === 0)) {
      this.btnList.push(this.addSubDetails);
    }
    if (showModel === 2 && relatedPlanFlag === 0) {
      this.btnList.push(this.linkSetting);
    } else if (showModel === 1 && relatedPlanFlag === 0) {
      this.btnList.push(this.selectSetting);
    }
    if (Number(codeCheckIn) === 0 && !editable) {
      this.noEditBtnList.push(this.exportSetting);
    }
    detailImport === "0" && this.btnList.push(this.importSetting);
    this.showContent.push(this.column.uuid);// 关闭折叠设置开关 默认展开
    if (this.column.editable && this.column?.subDetails?.length === 0 && !this.column.IsCardType) {
      this.column.addOneLine(AssignmentOptions.default);
    }
    console.log(this.column, "明细数据");
    // eslint-disable-next-line max-len
    if (this.$route.query.openType === "1" && window.location.href.includes("businessModel/detail") && this.column.lineNum === "0" && this.isCheck()) {
      this.column.subDetails = [];
      console.log(this.column.subDetails, "sad");
    }
  }
};
</script>
<style scoped lang="scss">
.detail-content {
  width: 100%;
  margin: 0;
  .title-requred {
    &::after {
      content: "*";
      color: #ff3e3e;
      height: 100%;
      line-height: 100%;
    }
  }

  /deep/ .el-collapse-item__header{
    cursor: pointer;
  }
  /deep/ .vxe-header--column{
    background: #F2F5F8;
  }
  /deep/ .el-collapse-item__content{
    padding-bottom: 0;
  }
  /deep/ .el-collapse {
    border: none;
  }
  /deep/ .el-collapse-item__header {
    // background-color: #f5f7fa;
    // border: 1px solid #e8ecf2;
    // border-bottom: none;
    border-radius: 4px 4px 0 0;
    color: #333333;
    display: flex;
    .el-icon-arrow-right{
          display: none !important;
      }
    .header-add{
      flex: 1 1 auto;
      display: flex;
      justify-content: flex-end;
      padding-right: 15px;
      color: rgb(72, 149, 223);
    }
    &.focusing:focus:not(:hover) {
      color:#333333;
    }
  }
  /deep/ .el-collapse-item__wrap {
    // border-bottom: none;
  }
  &.cos-closeed {
    /deep/ .el-collapse-item__header {
      border-radius: 4px;
      // border: 1px solid #e8ecf2;
    }
  }
  &.no-border {
    border-color: transparent;
    .name {
      background-color: transparent;
      padding-left: 0px;
    }
    /deep/ .el-collapse-item__header {
      background-color: transparent;
      border: none;
      height: 64px;
      line-height: 64px;
    }
  }
  // & /deep/ .vxe-table--header-wrapper,& /deep/ .vxe-header--row>.is--firstCol,& /deep/ .vxe-head-end-wrapper{
  //   background: #f6fafd!important;
  // }
  /deep/ .bm-sub-content .vxe-header--column{
    background: #EEF8F3!important;
  }
  /deep/ .vxe-header--column{
    font-size: 14px!important;
    font-weight: 400;
    color: #1A1C1E!important;
  }
  /deep/ .vxe-header--column:first-of-type .vxe-cell {
    padding: 0;
    text-align: center;
  }
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:flex-start;
  }
  .detail-btn {
    width: 80px;
    height: 32px;
    border-radius: 4px;
    margin: 0 5px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #636c78;
    cursor: pointer;
    .en-icon {
      margin-right: 6px;
    }
  }
  .name {
    background-color: transparent;
    font-weight: bold;
    // background-color: #f5f7fa;
    // height: 48px;
    // line-height: 48px;
    font-size: 14px;
    .drop-down{
      margin-left: 5px;
      width:12px;
      height:12px;
    }
  }
  .input-with-select{

    vertical-align: middle;
    align-self: center;
    display: flex;
    flex: 1 300px;
    margin-top: 3px;
  }
  .dialog-content{
    text-align: center;
  }
  .fiexdlie{
    color: rgb(51, 112, 255);
    padding-right: 5px;
    cursor: pointer;
  }
  /deep/ .vxe-table.t--border .vxe-table--header-wrapper{
    border-right: 1px solid #F2F5F8;
    border-top: 1px solid #F2F5F8;
    border-left: 1px solid #F2F5F8;
  }
}
/deep/.vxe-table .vxe-head-end-wrapper{
  z-index: 99!important;
}
</style>
