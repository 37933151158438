<!--
 * @Descripttion: 卡片式
 * @Author: 彭博
 * @Date: 2020-07-27 10:28:46
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-03-04 11:45:16
-->
<template>
  <div class="detail-card">
      <div class="add-card" v-if="!overflow && column.editable"  @click="generateTemp">
          <en-icon name="jia-cengjijiegou" style="color:#3E90FE" size="14px"> </en-icon>
          <div class="text">新增</div>
    </div>
    <div  v-for="(card, index) in detailList" :key="index">
      <en-card
        type="normal"
        :edit-able="column.editable"
        :menu-list="menuList"
        :dataInfo="{
          card: column.subDetails[index],
          index: index
        }"
        width="auto"
        height='auto'
        @change="menuChange"
      >
      <template slot="icon">
        <div class='icon-left' :style="iconStyle">
          <en-icon v-if="cardIconConfig.sourceType === '2' " :name="iconName"  size="small" class='icon-item'>  </en-icon>
          <!-- <img v-else :src="setIconName(index)" alt="" class='img-icon'> -->
          <en-icon v-else-if="Number(column.fieldType) === 19" :name="bankIcon(card.bank[0].id)"  size="56" class='icon-item'>  </en-icon>
          <en-user-logo
            v-else
            :class='{"noImgBk":!setIconName(index).url&&!setIconName(index).userName}'
            :user-name="setIconName(index).userName"
            :image-url="setIconName(index).url"
            :icon='!setIconName(index).url&&!setIconName(index).userName?setIconName(index).icon:""'
            :size="46"
          ></en-user-logo>
        </div>
      </template>
        <template slot="content">
          <div class="card-content">
            <div class="card-item" v-for="(key, tIndex) in computedItemCard(index)" :key="tIndex" >
              <div class="ellipsis-text"><span :title="formatCardText(computedItemCard(index)[tIndex])" v-show="isShow(tIndex)">{{ formatCardText(computedItemCard(index)[tIndex]) }}</span></div>
            </div>
          </div>
        </template>
      </en-card>
    </div>
    <!-- 新增弹窗 -->
    <card-dialog :title="column.name" ref="addDialog" :column="column" :businessData="businessData" :checkProp="checkProp" @entered="enterCard"></card-dialog>
  </div>
</template>

<script>
import { getFileUrl } from "@/tools/getFileUrl";
import cardDialog from "../addType/cardAdd";
import { AssignmentOptions, BusinessFieldType } from "../../../../data/BusinessCommonHeader";

export default {
  name: "detaiCard",
  componentName: "detaiCard",
  components: {
    cardDialog
  },
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    },
    // prop校验路径
    checkProp: {
      type: String,
      default: "column"
    }
  },
  computed: {
    computedItemCard() {
      return (index) => {
        const curentItem = this.detailList[index];
        const showArray = [0, 0, 0, 0];
        const newItem = {};
        for (let j = 0; j < this.column.sub.length; j++) {
            if (this.column.sub[j].showsSort && this.column.sub[j].showsSort !== "0") {
                // eslint-disable-next-line radix
                showArray[parseInt(this.column.sub[j].showsSort) - 1] = {
                  field: this.column.sub[j].field,
                  value: curentItem[this.column.sub[j].field]
                };
            }
        }
        showArray.forEach((it) => {
          newItem[it.field] = it.value;
        });
        return newItem;
      };
    },
    overflow() {
      return this.column.maxNum && this.column.subDetails.length >= this.column.maxNum;
    },
    baseCardData() {
      const defaultParams = {};
      this.column.sub.forEach((item) => {
        defaultParams[item.field] = this.column.valueToText(item.defaults, item);
      });
      return defaultParams;
    },

    cardIconConfig() {
      return this.column.cardIconConfig || {};
    },
    iconName() {
      const nameMap = {
        1: "bus-ywmb-icon22",
        2: "jine-kongjian",
        3: "renyuan-wodehangcheng",
        4: "bus-ywmb-icon24",
        5: "bus-ywmb-icon23",
        6: "bus-board-icon3",
        7: "bus-board-icon4",
        8: "bus-board-icon5",
        9: "bus-board-icon18",
        10: "bus-board-icon7"
      };
      return this.cardIconConfig.icon ? nameMap[this.cardIconConfig.icon] : "renyuan-wodehangcheng";
    },
    iconStyle() {
      return {
          background: this.cardIconConfig.sourceType === "2" ? (this.cardIconConfig.color || "#49bef2") : ""
      };
    }
  },
  data() {
    return {
      temporarySection: null,
      detailList: [], // 详情 或已添加成功数据保存集合
      baseMdData: [],
      menuList: [
        {
          id: 1,
          name: "编辑",
          fn: this.editCard
        },
        {
          id: 2,
          type: "allowConflict",
          name: "复制",
          fn: this.copyOneLine
        },
        {
          id: 3,
          type: "completeFlag",
          name: "删除",
          fn: this.deleteOneLine
        }
      ]

    };
  },
  watch: {
    "column.subDetails": {
      deep: true,
      immediate: true,
      handler() {
        console.log("~~~~~~~~~~~~~~detail list ~~~~~~~~~~~~~~~~~~", this.column.paramsForSubmit, this.column.field, this.businessData, this.businessData.originData.mdData[this.column.field]);
        this.detailList = this.column.paramsForSubmit?.[this.column.field] || [];
        this.baseMdData = this.businessData.originData.mdData[this.column.field];
      }
    }
  },
  methods: {
    // 银行图标
    bankIcon(bankId) {
      /* eslint-disable */
      const bankImages = {
        "001": "renmin",
        "101": "gongshang",
        "102": "nongye",
        "103": "zhongguo",
        "104": "jianshe",
        "204": "youchu",
        "301": "jiaotong",
        "302": "zhongxin",
        "303": "guangda",
        "304": "huaxia",
        "305": "minsheng",
        "306": "guangfa",
        "307": "pingan",
        "308": "zhaoshang",
        "309": "xingye",
        "310": "pufa",
        "311": "bohai",
        "312": "henfeng",
        "314": "huishang",
        "501": "huifeng",
        "502": "dongya",
        "503": "hensheng",
        "508": "zhaoshang",
        "509": "daxin",
      };
      if (bankImages[bankId]) {
        return bankImages[bankId];
      }
      return "yinhanglogo";
    },
    isShow(key) {
      // console.log(this.column, key);
     const curField = this.column.sub.find((it) => it.field === key);
     return curField?.displayable;
    },
    
    setIconName(ind) {
      const res = {
        userName: "",
        url: "https://images.softfuxi.com/fx1739239309467.png",
        icon: ""
      };
      if (this.cardIconConfig.personFieldArea === "main") {//取主表字段
        const showValue = this.column.findMainAreaField(this.cardIconConfig.personField).showValue || {};
        res.url = showValue?.userLogo||getFileUrl(this.baseMdData?.[ind].cardIconUrl, '000', showValue.name);
        res.userName = showValue.name;
      } else if (this.column.fieldType === BusinessFieldType.shouFu) { // 收付信息
        res.icon = this.detailList[ind] && this.detailList[ind].bank && this.bankIcon(this.detailList[ind].bank[0].id);
      } else {
        let  getFieldValue=this.detailList[ind] && this.detailList[ind][this.cardIconConfig.personField] && this.detailList[ind][this.cardIconConfig.personField][0]
        res.userName =getFieldValue?.name
        res.url = getFieldValue?.userLogo;
        if(!res.url&&this.baseMdData?.[ind]?.cardIconUrl){
          res.url=getFileUrl(this.baseMdData[ind].cardIconUrl, '000', res.userName)
        }
      }
      return res;
    },
    // 菜单change
    menuChange(menu, info) {
      const { card, index } = info;
      
      menu.fn(card, index);
    },
    // card 内容处理
    formatCardText(value) {
      Object.prototype.toString.call(value) === "[object Array]" && (value = value.map((el) => el.name).join(","));
      return `${value}`;
    },
    generateTemp(values) {
      // 复制一个card
      const line = this.column.createOneLineData(); // 临时生成一个
      this.temporarySection = line;
      this.column.setLineValue(line, AssignmentOptions.manual, values || null);
      this.$refs.addDialog.openDialog(line, values);
    },
    // 打开弹窗
    openDialog(card, index) {
      this.$refs.addDialog.openDialog(card, this.detailList[index], index);
    },
    // 弹窗 填写回调
    enterCard(enterValue, index) {
      this.baseMdData?.[index]?this.baseMdData[index].cardIconUrl='':""
      if (index !== undefined) {
        this.column.subDetails.splice(index, 1, this.column.subDetails[index]);
      } else {
        // 复制时 填写完成需要手动push
        this.column.subDetails.push(this.temporarySection);
      }
      this.column.updateValueWithOptions(enterValue, AssignmentOptions.manual);
    },
    // 编辑
    editCard(card, index) {
      this.openDialog(card, index);
    },
    // 新增一行
    addOneLine() {
      if (Number(this.column.maxNum) && this.column.subDetails?.length >= this.column.maxNum) {
        return;
      }
      this.column.addOneLine();
    },
    deleteOneLine(card, index) {
      // 删除当前card
      this.column.deleteOneLine(index,card); // 同时删除column数据
    },
    // 复制一行
    copyOneLine(card, index) {
      const len = this.column.subDetails.length;
      if (Number(this.column.maxNum) && len >= this.column.maxNum) {
        this.$message(`该明细最大支持${this.column.maxNum}行`);
      }
      // 复制一个card
      const data = this.detailList[index];
      this.generateTemp(data);
    },
    removeEmpty() {
      this.detailList = this.column.paramsForSubmit?.[this.column.field]; // 填充detailList
      this.baseMdData = this.businessData.originData.mdData[this.column.field];
      console.log("~~~~~~~~~~~~~~detail list ~~~~~~~~~~~~~~~~~~", this.column.paramsForSubmit, this.detailList);
      // this.column.subDetails.splice(this.detailList.length); // 填充数据逻辑
    }
  },
  created() {
    this.removeEmpty();
  }
};
</script>

<style lang="scss" scoped>
/deep/.en-card-normal{
  color: #3E90FE;
  cursor: pointer;
  width: 400px !important;
  height: 152px!important;
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid rgba(62, 144, 254, 0.5);
  border-radius: 4px;
  // margin-top: 20px;
  // 悬浮状态下的样式处理
  .en-card-normal-main{
    margin-left: 6px!important;
    font-size: 13px;
    color: #1B1C1E;
  }
  &:hover {
    .content-edit {
      display: block;
    }
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  }
  &/deep/ .en-card-normal-icon{
      width: 100%;
      height: 54px;
      padding: 0px;
    }
    .icon-left{
      border-radius: 100%;
      width: 47px;
      height: 47px;
      line-height: 47px;
      margin-top: -6px;
      display: flex;
      justify-content: center;
      align-items: center;
      & /deep/ .icon-item{
        width: 28px;
        height: 28px;
        color: #fff;
      }
      .img-icon{
        width: 46px;
        height: 46px;
        border-radius: 100%;
      }
      .noImgBk{
        background-color: #e1ecf3!important;
      }
    }

}
.card-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  // padding: 5px 0;
  width: 100%;
  height: 68px;
  .card-item {
    width: 48%;
    height: 20px;
    line-height: 20px;
    &:nth-child(even) {
      text-align: right;
    }
  }
  .ellipsis-text {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1B1C1E;
    font-size: 13px;
  }
}

.detail-card {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  padding-bottom: 13px;
  // .en-card{
  //   margin-top: 20px;
  //   margin-right: 20px;
  // }
  .en-card{
    width: 420px!important;
    height: 96px!important;
    border: 1px solid #E8ECF2;
    box-shadow:none!important;
    padding: 28px 18px;
   
  }
  .en-card:hover{
      width: 420px!important;
      height: 96px!important;
      background: #ECF4FF;
      border: 1px solid rgba(62,144,254,0.5);
      box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
    }
 
}
/deep/ .en-card-normal .en-card-normal-menu{
      top: 5px!important;
}
.add-card {
  color: #3E90FE;
  cursor: pointer;
  width: 420px;
  height: 96px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid rgba(62,144,254,0.5);
  border-radius: 4px;
  // margin-top: 20px;
  // 悬浮状态下的样式处理
  &:hover {
    .content-edit {
      display: block;
    }
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
  }
  .en-icon {
    color: #bebebe;
  }
  .text{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #3E90FE;
  }
}
</style>
